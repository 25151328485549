import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";

export default function Cursos() {
    const [t, i18n] = useTranslation("global");
    
    return (
        
    <div className="container cursos-container">
        <Navbar/>
        <h1 className="title">
            {t("courses.title")}
        </h1>
        <div className="cursos">
            <img src="/media/musica_congelada.jpeg" alt="cursos" />
            <img src="/media/mascara_teatral.jpeg" alt="cursos" />
            <div>
                <img src="/media/expresivas-fr_esp.jpeg" alt="cursos" />
                <a target="_blank" href="https://www.youtube.com/watch?v=ksYYB6YMtic">
                    <img src="./media/yt_logo.png" className="yt-logo" alt="" />
                </a>
            </div>
            <div>
                <img src="/media/expresivas-fr_fr.jpeg" alt="cursos" />
                <a target="_blank" href="https://www.youtube.com/watch?v=eg4LgJszwIg&ab_channel=mascarasiriarteIriarte">
                    <img src="./media/yt_logo.png" className="yt-logo" alt="" />
                </a>
            </div>
        </div>
        <Footer/>
    </div>
    );
}