import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";

export default function Contact() {
    const [t, i18n] = useTranslation("global");
/*     const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [showFooter, setShowFooter] = useState(true);

    function encode(data) {
        return Object.keys(data)
          .map(
            (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&");
    }
    
    function handleSubmit(e) {
        e.preventDefault();
        console.log(name);
        console.log(email);
        console.log(message);
        fetch("/", {
          method: "POST",
          action: "https://formspree.io/f/mayzbgyk",
          body: encode({ "form-name": "contact", name, email, message }),
        })
          .then(() => alert("Message sent!"))
          .catch((error) => alert(error));
    }


    function handleInputChange() {
        if (window.innerWidth < 768) {
          setShowFooter(false);
        }
    }
    
    function handleWindowResize() {
        if (window.innerHeight < window.outerHeight) {
            setShowFooter(false);
        } else {
            setShowFooter(true);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
     */
    
    return (
        
    <div className="container">
        <Navbar/>
        <div id="contact">
            <h2>
                {t("contact.title")}
            </h2>
            {/* <form name="contact-form" method="POST" data-netlify="true" >
                <input type="hidden" name="form-name" value="contact-form" />
                <div className="inputbox">
                    <label htmlFor="name" >
                    {t("contact.form.name")}
                    </label>
                    <input onClick={handleInputChange} onBlur={() => setShowFooter(true)} onChange={(e) => setName(e.target.value)} type="text" name="name" />
                </div>
                <div className="inputbox">
                    <label htmlFor="email" >
                    {t("contact.form.email")}
                    </label>
                    <input onClick={handleInputChange} onBlur={() => setShowFooter(true)} onChange={(e) => setEmail(e.target.value)} type="email" name="email" />
                </div>
                <div className="inputbox">
                    <label htmlFor="message" >
                    {t("contact.form.message")}
                    </label>
                    <textarea onClick={handleInputChange} onBlur={() => setShowFooter(true)} onChange={(e) => setMessage(e.target.value)} id="message-label" name="message" />
                </div>
                <button type="submit" onClick={handleSubmit}>
                    {t("contact.form.button")}
                </button>
            </form> */}
            <span>alfredo@mascarasiriarte.com.ar</span>
        </div>
        {/* {showFooter && } <Footer/> */}
        <Footer/>
    </div>
    );
}