import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";

export default function Home() {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="container">
      <Navbar/>
      <div className="content">
        <video id="video" loop autoPlay>
          <source src="/media/masks_video.mp4" />
        </video>
        <h1>{t("home.title")}</h1>
      </div>
      <Footer/>
    </div>
  );
}