import React from 'react';
import './App.css';
import Contact from './routes/Contact';
import Home from './routes/Home';
import Video from './routes/Video';
import Cursos from './routes/Cursos';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path={`${__dirname}/contacto`} element={ <Contact /> } />
        <Route path={`${__dirname}/video`} element={ <Video /> } />
        <Route path={`${__dirname}/cursos`} element={ <Cursos /> } />
      </Routes>
    </Router>
  );
}

export default App;
