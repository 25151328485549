import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import YouTube from 'react-youtube';
import { useTranslation } from "react-i18next";

export default function Video() {
  const [t, i18n] = useTranslation("global");

  const opts = {
    height: window.innerHeight < 700 ? "200" : "400",
    width: window.innerWidth < 500 ? "300" : "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }


  return (
    <div className="container">
      <Navbar/>
      <div className="content">
        <h1>{t("video.title")}</h1>
        <YouTube videoId="AK_uJO4F45U" opts={opts} onReady={_onReady} />
      </div>
      <Footer/>
    </div>
  );
}