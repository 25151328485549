import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Navbar() {
    const [t, i18n] = useTranslation("global");
    const [language, setLanguage] = React.useState("es");

    function changeLanguage(lan) {
        i18n.changeLanguage(lan);
        setLanguage(lan);
        localStorage.setItem('language', lan);
    }

    useEffect(() => {
        const lan = localStorage.getItem('language');
        if (lan) {
            changeLanguage(lan);
        }
    }, []);

    return (
        <header id='header'>
            <div id='left-side'>
                <Link style={{"borderRadius": "30px"}} to="/">
                    <img src="/media/mascarasiriarte.png" alt="masklogo" />
                </Link>
                <h1>{ t("header.title") }</h1>
            </div>
            <div id='right-side'>
                <li>
                    <div className="lang-handler">
                        <img id="droplangs" src={`/media/${language}-flag.png`} alt="ES-FLAG"/>
                        <div className="dropdown-langs">
                            <img src="/media/es-flag.png" alt="ES-FLAG" onClick={() => changeLanguage("es")}/>    
                            <img src="/media/en-flag.png" alt="EN-FLAG" onClick={() => changeLanguage("en")}/>   
                        </div>
                    </div>
                    <a href="/">{t("header.sections.home")}</a>
                    <a href="/contacto">{t("header.sections.contact")}</a>
                    <a href="/cursos">{t("header.sections.courses")}</a>
                </li>
            </div>
        </header>
    );
}