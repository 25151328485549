import React from "react";

export default function Footer() {

    const openInNewTab = url => window.open(url, '_blank', 'noopener,noreferrer');

    return (
        <footer>
          <button className='socialmedia' onClick={() => openInNewTab('https://instagram.com/mascarasiriarte')}>
            <img src="/media/instagram_logo.png" alt="Instagram Logo" />
            <p>@mascarasiriarte</p>
          </button>
          <button className='socialmedia' onClick={() => openInNewTab('https://facebook.com/profile.php?id=100010327597874')}>
            <img src="/media/facebook_logo.png" alt="Instagram Logo" />
            <p>@Alfredo Iriarte</p>
          </button>
        </footer>
    );
}